import React, { useState } from "react";
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function LandingProducts() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const shopAtImages = [
        { name: "Swiggy Instamart", image: "../../images/landing/swiggy-instamart.svg", alt: "instamart" },
        { name: "Blinkit", image: "../../images/landing/blinkit-desk.svg", alt: "blinkit" },
        { name: "Amazon Fresh", image: "../../images/landing/fresh-desk.svg", alt: "amazon fresh" },
        { name: "Big Basket", image: "../../images/landing/bb-desk.svg", alt: "big basket" },
    ];

    const products = [
        {
            id: 1,
            name: "CLASSIC CHICKEN NUGGETS",
            image: "../../images/landing/Classic-Chicken-Nuggets.png",
            alt: "Classic Chicken Nuggets",
            shopAt: [
                { name: "Swiggy Instamart", link: "https://www.swiggy.com/instamart" },
                { name: "Blinkit", link: "https://www.blinkit.com" },
                { name: "Amazon Fresh", link: "https://www.amazon.in/fresh" },
                { name: "Big Basket", link: "https://www.bigbasket.com" },
                { name: "More", link: "" },
            ],
        },
        {
            id: 2,
            name: "CHICKEN POPCORN",
            image: "../../images/landing/Chicken-Popcorn.png",
            alt: "Chicken Popcorn",
            shopAt: [
                { name: "Swiggy Instamart", link: "https://www.swiggy.com/instamart" },
                { name: "Blinkit", link: "https://www.blinkit.com" },
                { name: "Amazon Fresh", link: "https://www.amazon.in/fresh" },
                { name: "Big Basket", link: "https://www.bigbasket.com" },
                { name: "More", link: "" },
            ],
        },
        // Add more products as needed
    ];

    const handleOpenModal = (product) => {
        setIsOpen(true);
        setSelectedProduct(product);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setSelectedProduct(null);
    };

    return (
        <>
            <section className="landing-product-list">
                <div className="container py-5">
                    <div className="row align-items-end">
                        {products.map((product) => (
                            <div key={product.id} className="col-md-3 col-6 mb-4">
                                <div className="landing-prodcard text-center">
                                    <img src={product.image} width="155" height="230" alt={product.alt} />
                                    <div className="prod-titlecard">
                                        <h3>{product.name}</h3>
                                        <button className="btn btn-land">SHOP NOW AT</button>
                                        <div className="prod-shopat">
                                            {product.shopAt.map((shop, index) => (
                                                shop.name === "More" ? (
                                                    <a key={shop.name} className="d-md-none" onClick={() => handleOpenModal(product)}>+ 3 more</a>
                                                ) : (
                                                    <a key={shop.name} href={shop.link} target="_blank" rel="noreferrer" className={["Blinkit", "Amazon Fresh", "Big Basket"].includes(shop.name) ? "d-none d-md-block" : ""}>
                                                        <img src={shopAtImages.find((image) => image.name === shop.name).image} alt={shop.name} className={index === 0 ? 'swiggy-image' : ''} />
                                                    </a>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => handleCloseModal()}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000,
                    },
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent', // Changed to transparent
                        padding: '20px',
                        border: 'none',
                        borderRadius: '10px',
                        overflow: 'none',
                        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                        zIndex: 1001,
                        width: '100%', // Increased width for the modal
                        height: 'auto', // Changed to auto to fit the content
                    },
                }}
            >
                {selectedProduct && (
                    <div className="modal-content landing-product-list">
                        <button className="close-modal-btn" onClick={() => handleCloseModal()}>Close</button>
                        <div className="landing-prodcard text-center">
                        <img src={selectedProduct.image} width="155" height="230" alt={selectedProduct.alt} />
                        <div className="prod-titlecard">
                            <h3>{selectedProduct.name}</h3>
                            <button className="btn btn-land">SHOP NOW AT</button>
                            <div className="prod-shopat">
                                {selectedProduct.shopAt.filter(shop => shop.name !== "More").map((shop) => (
                                    <a key={shop.name} href={shop.link} target="_blank" rel="noreferrer">
                                        <img src={shopAtImages.find((image) => image.name === shop.name).image} alt={shop.name} />
                                    </a>
                                ))}
                            </div>
                        </div>
                        </div>
                    </div>
                )}
            </Modal>

            <style jsx>{`
                .prod-shopat {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 10px;
                }

                .swiggy-image {
                    display: block;
                    margin-bottom: 10px;
                    width: 100%;
                }

                .close-modal-btn {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    padding: 10px;
                    font-size: 16px;
                    cursor: pointer;
                    z-index: 1002; /* Added z-index to ensure the button is on top of the modal */
                }

                @media (min-width: 768px) {
                    .prod-shopat img:first-child {
                        margin-bottom: 15px;
                        width: 100%;
                    }

                    .prod-shopat img:not(:first-child) {
                        flex: 1;
                    }
                }
            `}</style>
        </>
    );
}
