import React from "react";

export default function LandProductRange() {
    return (
        <>
        <section className="product-range">
            <div className="container text-center">
                <div className="range-card">
                <img src="../images/landing/product-range.png" className="w-100" alt="" />
                <button className="btn btn-land">SHOP OUR RANGE OF PRODUCTS AT</button>
                <div className="store-fronts gap-2">
                    <a href="/" target="_blank" rel="noreferrer"><img src="../images/landing/swiggy-instamart1.svg" alt="" /></a>
                    <a href="/" target="_blank" rel="noreferrer"><img src="../images/landing/blinkit1.svg" alt="" /></a>
                    <a href="/" target="_blank" rel="noreferrer"><img src="../images/landing/fresh1.svg" alt="" /></a>
                    <a href="/" target="_blank" rel="noreferrer"><img src="../images/landing/bb1.svg" alt="" /></a>
                </div>
                </div>
            </div>
        </section>
        </>
    );
}