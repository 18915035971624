import React from "react";

export default function DqLandPre() {
    return (
        <>
        <footer className="dqland-pre">
            <img src="../images/landing/dq-pre.png" alt="" className="w-100" />
            <div className="row pb-4 text-center bg-white">
        <div className="container">
          <p className="mt-4 mb-0 text-black">&copy; ALL RIGHTS RESERVED TO SNEHA GROUP</p>
        </div>
      </div>
        </footer>
        </>
    );
}