import { Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './components/RouteScrollTop';
import Layout from './components/Layout';
import Home from './pages/Home';
import LandingPage from './pages/LandingPage';
import PartySnack from './components/AboutSnackster';
import ProductPage from './components/ProductPage';
import RecipePage from './components/recipePage';
import ProductCollections from './components/ProductCollections';

const ProductDes = React.lazy(() => import('./pages/ProductDes'));
const RecipeDetail = React.lazy(() => import('./components/recipeDetailed'));

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/about/' element={<PartySnack />} />
          <Route path='/products/' element={<ProductPage />} />
          <Route 
            path="/products/:productUrl" 
            element={<React.Suspense fallback={<div>Loading...</div>}>
                        <ProductDes />
                      </React.Suspense>} 
          />
          <Route path="/recipes/" element={<RecipePage />} />
          <Route path="/recipes/:recipeSlug" element={<React.Suspense fallback={<div>Loading...</div>}>
                        <RecipeDetail />
                      </React.Suspense>} />
          <Route path="/collections/:collectionUrl" element={<ProductCollections />} />
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Route>

        <Route path="/party-snacks" element={<LandingPage />} />
      </Routes>
    </>
  );
}

export default App;
