import React from 'react';
import { Helmet } from 'react-helmet';
// import Brands from '../components/Brands';
// import Projects from '../components/Projects';
// import Testimonial from '../components/Testimonial';
// import Hero from '../components/Hero';
// import About from '../components/About';
// import Skills from '../components/Skills';
// import HomePagdData from '../data/HomePagdData.json';
import Hero from '../components/Hero';
import IconGrid from '../components/iconsSection';
import ProductShowcase from '../components/productCards';
import CrossMarquee from '../components/crossMarquee';
import PartyMoreFun from '../components/recipeSection';
import ChickenNuggetsSlider from '../components/animatedSlider';
import TruckAnimation from '../components/truckAnimation';
// import AnimatedBackground from '../components/dqAnimateCircles';
import ImageHoverComponent from '../components/imageHover';
import MarqueeSlider from '../components/marqueeSlider';

// import SnacksterComponent from '../components/hangoutParty';
// import Experience from '../components/Experience';
// import Contact from '../components/Contact';

export default function Home() {
  // const {
  //   // hero,
  //   // socialBtns,
  //   // brands,
  //   // about,
  //   // projects,
  //   // skills,
  //   // experience,
  //   // testimonial,
  //   // contact,
  // } = HomePagdData;
  return (
    <>
    <Helmet>
        <title>Order Party Snacks Online | Frozen Snacks Ready to Cook for Every Occasion - Snackster</title>
        <meta name="description" content={`Order delicious frozen snacks online from Snackster! Perfect for every Party, our ready to cook party snacks make hosting easy. Quick prep, tasty results!`} />
      </Helmet>
      {/* <Hero data={hero} socialData={socialBtns} />
      <Brands data={brands} />
      <About data={about} />
      <Projects data={projects} /> */}
      <Hero />
      <IconGrid />
      <ProductShowcase />
      <CrossMarquee />
      <ImageHoverComponent />
      <MarqueeSlider />
      <ChickenNuggetsSlider />
      <PartyMoreFun />
      <TruckAnimation />

      {/* <SnacksterComponent /> */}
      {/* <Skills data={skills} /> */}
      {/* <Experience data={experience} />
      <Testimonial data={testimonial} />
      <Contact data={contact} socialData={socialBtns} /> */}
      
    </>
  );
}
