import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const preloadImages = (urls) => {
    urls.forEach((url) => {
        const img = new Image();
        img.src = url;
        img.loading = 'lazy'; // Added for native lazy-loading
    });
};

const IconGrid = () => {
    useEffect(() => {
        preloadImages([
            'images/breaded_icon_hover.svg',
            'images/coldcuts_icon_hover.svg',
            'images/wholemuscle_icon_hover.svg',
            'images/wrapped_icon_hover.svg'
        ]);
    }, []);

    return (
        <div className="bg-blue py-5" id='party'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3 col-6'>
                        <Link to="/collections/breaded"> <div className="icon-container breaded">
                            <center><div className="icon"></div></center>
                            <div className="icon-text">BREADED</div>
                        </div></Link>
                    </div>
                    <div className='col-md-3 col-6'>
                        <Link to="/collections/cold-cuts"> <div className="icon-container coldcuts">
                            <center><div className="icon"></div></center>
                            <div className="icon-text">COLD CUTS</div>
                        </div></Link>
                    </div>
                    <div className='col-md-3 col-6'>
                        <Link to="/collections/whole-muscle"><div className="icon-container wholemuscle">
                            <center><div className="icon"></div></center>
                            <div className="icon-text">WHOLE MUSCLE</div>
                        </div></Link>
                    </div>
                    <div className='col-md-3 col-6'>
                        <Link to="/collections/wrapped"> <div className="icon-container wrapped">
                            <center><div className="icon"></div></center>
                            <div className="icon-text">WRAPPED</div>
                        </div></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IconGrid;
