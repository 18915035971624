import React from "react";
import LandHeader from "../components/landing/LandHeader";
import LandMobileHeader from "../components/landing/LandmobileHeader";
import LandHero from "../components/landing/Hero";
import LandingProducts from "../components/landing/LandingProducts";
import CrossMarquee from "../components/crossMarquee";
import LandTruckAnimation from "../components/landing/LandtruckAnimation";
import ProductRange from "../components/landing/productRange";
import DqLandPre from "../components/landing/dqPrefooter";

export default function LandingPage() {
    return (
        <>
            <LandHeader />
            <LandMobileHeader />
            <LandHero />
            <LandingProducts />
            <CrossMarquee />
            <ProductRange />
            <LandTruckAnimation />
            <DqLandPre />
        </>
    );
}